import React, { useState } from "react"
import { getVariables, MAX_COMBINATIONS, generatePath } from "../lib/utils"
import { useStaticQuery, graphql, Link, navigate } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import srcIconCheck from "../images/icon-check.svg"
import srcIconPlus from "../images/icon-plus.svg"

const ListMattress = ({ container = false, className = null }) => {

    const mattresses = useStaticQuery(graphql`
    query {
        allDatoCmsMattress(
            filter: {
                locale: {eq: "en"}, 
                location: {
                    websiteUrl: {eq: "sg.mattress.zone"}
                }
            }, 
            sort: {
                fields: name, 
                order: ASC
                }
            ) {
            nodes {
                name
                slug
                location {
                    websiteUrl
                }
                mattressImage {
                    gatsbyImageData(
                        imgixParams: {auto: "format", fit: "crop", h: "125", w: "250", colors: 10, cornerRadius: "10,10,10,10", mask: "corners"}
                        placeholder: BLURRED
                    )
                }
                id
            }
        }
        datoCmsTranslation(locale: {eq: "en"}) {
            details
            compare
        }
    }
    `)

    const variables = getVariables()
    const [toggleButton, setToggleButton] = useState(false)
    const [checkedItems, setCheckedItems] = useState([])

    const handleToggle = (event) => {
        const { value, checked } = event.target
        let counter = checkedItems.length
        if (checked) {
            //adppend to checkedItems
            setCheckedItems(prev => [...prev, value])
            counter++
        } else {
            //remove from the uncheckedItems
            setCheckedItems(prev => prev.filter(item => item !== value))
            counter--
        }

        if (counter > 0) {
            setToggleButton(true)
        } else {
            setToggleButton(false)
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        navigate(
            generatePath(checkedItems, 'en', checkedItems.length > 1 ? variables.comparer : variables.mattress, variables.mattress),
            { state: { checkedItems } }
        )
    }

    const iconCheck = <img src={srcIconCheck} alt="✓" width="16" height="16" className="icon-check" />
    const wrapIconPlus = <div className="wrap-icon-plus"><img src={srcIconPlus} alt="+" width="16" height="16" title={variables.add_title} /></div>

    return (
        <div className={className}>
            <form className={` ${container ? 'container mx-auto' : null} `} onSubmit={handleSubmit} >
                <h4 className="text-lg text-zone-1 font-bold font-grotesk text-center mb-8">{variables.list_mattress_text}</h4>

                <div className="grid gap-6 grid-cols-1 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                    {mattresses.allDatoCmsMattress.nodes.map((mattress) => {
                        const image = getImage(mattress.mattressImage)
                        const singlePath = generatePath(
                            [mattress.slug],
                            'en',
                            variables.mattress,
                            variables.mattress
                        )
                        return (
                            <div className="item-mattress" key={`key-list-mattresses-${mattress.id}`}>
                                <input
                                    type="checkbox"
                                    id={`list-mattress-${mattress.id}`}
                                    name="selected_mattress"
                                    value={mattress.slug}
                                    onChange={handleToggle}
                                    disabled={
                                        checkedItems.length >= MAX_COMBINATIONS &&
                                        !checkedItems.includes(mattress.slug)
                                    }
                                />
                                {iconCheck}
                                <label htmlFor={`list-mattress-${mattress.id}`}>
                                    {wrapIconPlus}
                                    <GatsbyImage image={image} alt={mattress.name} className="image" />
                                    <h3>{mattress.name}</h3>
                                    <Link to={singlePath}>
                                        {mattresses.datoCmsTranslation.details}
                                    </Link>
                                </label>
                            </div>
                        )
                    }
                    )}
                </div>
                <div>
                    <button
                        type="submit"
                        className={`fixed right-0 left-0 bottom-20 mx-auto w-60 md:w-80 bg-zone-11 rounded-lg
                        text-white shadow-lg transition-all duration-300 ease-in border-none text-2xl 
                        uppercase z-50 hover:bg-zone-9 flex items-center justify-center py-2 px-4 font-bold 
                        ${toggleButton ? 'opacity-100 transform scale-100' : 'opacity-0 transform scale-0'} `}
                        id="btn-compare">
                        <span className="mr-4 text-4xl">&#8644;</span>
                        <span>{mattresses.datoCmsTranslation.compare}</span>
                    </button>
                </div>
            </form>
        </div>
    )
}

export default ListMattress